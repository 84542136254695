import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { TENANT_CODE_PARAM } from '../components/lib';
import { Feature } from '../utils/features-constants';
import { useAuth } from './AuthContext';
import {
  useGetTenantFeaturesQuery,
  useGetTenantQuery,
  useGetUserFeaturesQuery,
} from '../generated-mesh/graphql';

/**
 * All feature flags are tracked here: https://www.notion.so/futurefitai/Feature-Flag-Inventory-20a533696203499eb3df27f7a92ef9b7?pvs=4.
 * If any changes are made please ensure to update the inventory so we have an up-to-date list of
 * feature flags.
 */
type FeatureContextType = {
  features: string[];
  isAdditionalLangsSpanishPortugueseEnabled?: boolean;
  isEmployerPortalEnabled?: boolean;
  isExtractSkillsFromJobDescription?: boolean;
  isFormsImprovementsEnabled?: boolean;
  isFormsV3DefaultQuestionsEnabled?: boolean;
  isHideIntercomEnabled?: boolean;
  isJobSearchLocationConfigEnabled?: boolean;
  isLoading?: boolean;
  isRecruiterOutreachEnabled?: boolean;
  isLiveTranslatorEnable?: boolean;
  isLLMResumeServiceEnabled?: boolean;
  isLanguageFilterEnabled?: boolean;
  isLookerInsightsEnabled?: boolean;
  isLearningSidePanelSkillsAndCareersEnabled?: boolean;
  isInviteLinksValidateEmailDomainsEnabled?: boolean;
  isHideCareerDemandEnabled?: boolean;
  isLiveTranslationServiceEnabled?: boolean;
  isATSEasyApplyEnabled?: boolean;
  isPolicyDisplaySignUpEnabled?: boolean;
  isJobAttributesFiltersEnabled?: boolean;
  isWorkSectionVectorSearchEnabled?: boolean;
  isLearningResourceHideCostFilterEnabled?: boolean;
  isUseLLMParsedSkillsEnabled?: boolean;
  isLLMSkillsForManualExperiencesEnabled?: boolean;
  isRoleBasedPermissionsEnabled?: boolean;
};

export const FeatureContext = createContext<FeatureContextType>({
  features: [],
});

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [features, setFeatures] = useState<string[]>();
  const [{ data: userFeaturesData, fetching: userFeaturesFetching }] =
    useGetUserFeaturesQuery({
      pause: !user.userId,
    });

  const [searchParams] = useSearchParams();
  const tenantCodeInParams = searchParams.get(TENANT_CODE_PARAM);

  const [{ data: tenantData, fetching: tenantFetching }] = useGetTenantQuery({
    variables: { tenantCode: tenantCodeInParams! },
    pause: !!user.userId || !tenantCodeInParams,
  });

  const [{ data: tenantFeaturesData, fetching: tenantFeaturesFetching }] =
    useGetTenantFeaturesQuery({
      variables: {
        tenant_uuid: tenantData?.getTenant?.id!,
      },
      pause:
        !!user.userId ||
        !tenantCodeInParams ||
        tenantFetching ||
        !tenantData?.getTenant,
    });

  const featuresData = !user.userId
    ? tenantFeaturesData?.getTenantFeatures
    : userFeaturesData?.getUserFeatures;

  /**
   * Grab all feature flags for the user
   */
  useEffect(() => {
    const featureSlugs = featuresData?.map((f) => f.slug);
    setFeatures(featureSlugs);
  }, [featuresData]);

  const value: FeatureContextType = {
    features: features || [],
    isAdditionalLangsSpanishPortugueseEnabled: features?.includes(
      Feature.ADDITIONAL_LANGS_ES_PT
    ),
    isEmployerPortalEnabled:
      features?.includes(Feature.EMPLOYER_PORTAL) || false,
    isExtractSkillsFromJobDescription:
      features?.includes(Feature.EXTRACT_SKILLS_FROM_JOB_DESCRIPTION) || false,
    isFormsImprovementsEnabled:
      features?.includes(Feature.FORMS_IMPROVEMENTS) || false,
    isFormsV3DefaultQuestionsEnabled:
      features?.includes(Feature.FORMS_V3_DEFAULT_QUESTIONS) || false,
    isHideIntercomEnabled: features?.includes(Feature.HIDE_INTERCOM) || false,
    isJobSearchLocationConfigEnabled:
      features?.includes(Feature.JOB_SEARCH_LOCATION_CONFIG) || false,
    isLoading: userFeaturesFetching || tenantFeaturesFetching || tenantFetching,
    isRecruiterOutreachEnabled:
      features?.includes(Feature.RECRUITER_OUTREACH) || false,
    isLiveTranslatorEnable:
      features?.includes(Feature.LIVE_TRANSLATION) || false,
    isLLMResumeServiceEnabled:
      features?.includes(Feature.LLM_RESUME_SERVICE) || false,
    isLanguageFilterEnabled:
      features?.includes(Feature.LANGUAGE_FILTER) || false,
    isLookerInsightsEnabled:
      features?.includes(Feature.LOOKER_INSIGHTS) || false,
    isLearningSidePanelSkillsAndCareersEnabled:
      features?.includes(Feature.LEARNING_SIDE_PANEL_SKILLS_AND_CAREERS) ||
      false,
    isInviteLinksValidateEmailDomainsEnabled:
      features?.includes(Feature.INVITE_LINKS_VALIDATE_EMAIL_DOMAINS) || false,
    isHideCareerDemandEnabled:
      features?.includes(Feature.HIDE_CAREER_DEMAND) || false,
    isLiveTranslationServiceEnabled:
      features?.includes(Feature.LIVE_TRANSLATION_SERVICE) || false,
    isATSEasyApplyEnabled: features?.includes(Feature.ATS_EASY_APPLY) || false,
    isPolicyDisplaySignUpEnabled:
      features?.includes(Feature.POLICY_DISPLAY_SIGN_UP) || false,
    isJobAttributesFiltersEnabled:
      features?.includes(Feature.JOB_ATTRIBUTES_FILTERS) || false,
    isWorkSectionVectorSearchEnabled:
      features?.includes(Feature.WORK_SECTION_VECTOR_SEARCH) || false,
    isLearningResourceHideCostFilterEnabled:
      features?.includes(Feature.LEARNING_RESOURCE_HIDE_COST_FILTER) || false,
    isUseLLMParsedSkillsEnabled:
      features?.includes(Feature.USE_LLM_PARSED_SKILLS) || false,
    isLLMSkillsForManualExperiencesEnabled:
      features?.includes(Feature.LLM_SKILLS_FOR_MANUAL_EXPERIENCES) || false,
    isRoleBasedPermissionsEnabled:
      features?.includes(Feature.ROLE_BASED_PERMISSIONS) || false,
  };

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
};

/**
 * Get a list of toggleable features available for the user's tenant,
 * @example const { isWorkRecommenderEnabled } = useFeatures(); // true
 *          const { features } = useFeatures(); // ['work-recommender', 'employer-portal']
 */
export const useFeatures = () => {
  const context = useContext(FeatureContext);
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeatureProvider');
  }
  return context;
};

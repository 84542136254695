import React, {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useIntersectionObserver } from '@react-hookz/web';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import {
  Button,
  CheckboxInput,
  DropdownSelect,
  UniversalDropdownSelect,
  Heading,
  IconCancel,
  IconClipboard as IconInterviews,
  IconCoffee as IconMentorship,
  IconCollapse,
  IconExpand,
  IconIncrease as IconFinancialPlanning,
  IconInkPen as IconFreelance,
  IconLeaf as IconHealthAndWellness,
  IconLuggage as IconJobSearch,
  IconPay as IconPayNegotiation,
  IconProfile as IconResume,
  IconRocket as IconEntreprneurship,
  IconShow as IconPersonalBrand,
  IconSpeechBubble as IconNetworking,
  IconWallet as IconFinancialSupport,
  RadioInput,
  Text,
} from '@futurefit-ai/ffui';
import type { Option } from '@futurefit-ai/ffui';

import { ENTER, SPACE } from './constants';
import { SvgSearchIcon } from '../assets/images/SvgSearch';
import { SvgWebSearch } from '../assets/images/SvgWebSearch';
import {
  INTERNATIONAL_LOCALES,
  LanguageLabel,
  getFullLabels,
} from '../context/i18n';
import { useIsMobile } from '../hooks/useIsMobile';
import { toNumber } from '../utils';
import { Currency, CurrencyCode, SupportedCurrency } from '../utils/currency';
import { EducationType, getEducationTypeLabel } from '../utils/outcomes';
import { SvgNoJobsWorkRec } from '../assets/images/SvgNoJobsWorkRec';
import useLocales from '../hooks/useLocales';

import './lib.css';

export const TENANT_CODE_PARAM = 'tc';
export const INVITE_CODE_PARAM = 'ic';
export const EMAIL_PARAM = 'email';
export const USER_NAME_PARAM = 'u';
export const CONFIRMATION_CODE_PARAM = 'c';
export const SIGN_UP_REDIRECT_URL_PARAM = 'redirectURL'; // used for redirecting after signup
export const FFAI_DEFAULT_SITE_TITLE = 'FutureFit AI';
export const FFAI_DEFAULT_FAVICON_URL = '/favicon-16x16.png';
export const FFAI_DEFAULT_LOGO_URL = '/ffai_logo.png';
export const NO_OF_ITEMS_PER_LOAD = 18;
export const HIGH_MATCH_SKILL_SCORE = 80;
export const MATCH_SCORE_UPPER_LIMIT = 95;
export const MATCH_SCORE_LOWER_LIMIT = 5;
export const TAGS_LIMIT = 50;

export enum SectionCardVariant {
  DEFAULT = 'default',
  EDIT = 'edit',
  LINK = 'link',
  POLICY = 'policy',
  EDIT_DOWNLOAD = 'edit-download',
  EDIT_DELETE = 'editDelete',
  DOWNLOAD = 'download',
  ADD = 'add',
  ADD_EDIT = 'addEdit',
}

export interface FilterMenuProps {
  isVisible: boolean;
  onClose: () => void;
  showClearAll?: boolean;
}

export type Filter = {
  key: string;
  values: string[];
};

const StyledFilterModal = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: visible;
  }
`;

const StyledFilterMenu = styled.div`
  ${tw`absolute bottom-0 flex flex-col w-full pb-20 overflow-y-auto rounded-t top-24 bg-off-white`}
`;

export const FilterMenu: FC<FilterMenuProps> = ({
  isVisible,
  onClose,
  children,
  showClearAll = true,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clearFilters = () => {
    navigate({
      search: '?',
    });
  };

  const visiblePrefix = isVisible ? 'show' : '';

  return (
    <StyledFilterModal className={visiblePrefix}>
      <StyledFilterMenu>
        <div className="flex items-center justify-between p-4">
          <p className="text-sm font-medium font-secondary">
            {t('search.filter_menu.title', 'Filters')}
          </p>
          {showClearAll && (
            <p
              className="text-xs text-concrete"
              role="button"
              onClick={clearFilters}
            >
              {t('search.filter_menu.clear_all_btn', 'Clear all')}
            </p>
          )}
        </div>
        {children}
      </StyledFilterMenu>
      <div className="absolute bottom-0 z-50 flex items-center justify-between w-full p-4 bg-off-white">
        <p role="button" onClick={onClose}>
          {t('terms.cancel', 'Cancel')}
        </p>
        <Button onClick={onClose}>
          {t('search.filter_menu.apply_filters_btn', 'Apply filters')}
        </Button>
      </div>
    </StyledFilterModal>
  );
};

export type FilterToggleProps = {
  title: string;
  className?: string;
  visible?: boolean;
};

export const FilterToggle: FC<FilterToggleProps> = ({
  title,
  className = '',
  children,
  visible = false,
}) => {
  const [isVisible, setIsVisible] = useState(visible || false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`border-b border-dawn ${className}`}>
      <div
        role="button"
        className="flex justify-between p-4"
        onClick={handleClick}
      >
        <p className="text-xs font-medium font-secondary">{title}</p>
        {isVisible ? <IconCollapse /> : <IconExpand />}
      </div>
      <div className={`px-4 ${isVisible ? 'flex' : 'hidden'}`}>{children}</div>
    </div>
  );
};

export type LanguageDropdownProps = {
  value: string;
  onChangeLanguage: (lang: string) => void;
};
export const LanguageDropdown: FC<LanguageDropdownProps> = ({
  value,
  onChangeLanguage,
}) => {
  const { availableLangsForUser } = useLocales();
  return (
    <DropdownSelect
      id="lng"
      value={value}
      onChange={(e) => {
        onChangeLanguage(e.target.value);
      }}
    >
      {availableLangsForUser.map((lng, i) => {
        return (
          <option key={i} value={lng}>
            {getFullLabels(INTERNATIONAL_LOCALES[lng].label as LanguageLabel)}
          </option>
        );
      })}
      ``
    </DropdownSelect>
  );
};

export const ErrorMessage = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <div className={`max-w-xl text-center m-auto text-dusk ${className}`}>
      <Heading level={4} size="sm">
        {t(
          'global.error.label_1',
          "Oops! Looks like things aren't quite working as expected."
        )}
      </Heading>
      <div className="text-xs font-light text-dusk">
        <p className="my-6">
          {t(
            'global.error.label_2',
            'Try refreshing the page or contacting us at '
          )}
          <a
            href="mailto:help@futurefit.ai"
            className="text-purple hover:underline"
          >
            help@futurefit.ai
          </a>
          {t('global.error.label_3', 'for assistance')}
        </p>
      </div>
    </div>
  );
};

const BaseNoResults: FC<{
  title: string;
  sideways?: boolean;
  svg?: ReactNode;
  className?: string;
}> = ({ className, title, svg, sideways, children }) => {
  return (
    <div
      className={`${className} max-w-lg m-auto text-dusk ${
        sideways ? 'flex gap-4 items-center justify-between' : ''
      } `}
      data-testid="NoResultsComponent"
    >
      {svg ? (
        svg
      ) : (
        <SvgWebSearch className={`m-auto ${sideways ? '' : 'mb-10'}`} />
      )}
      <div>
        <Heading level={4} size="sm">
          {title}
        </Heading>
        <div className="text-xs font-light text-dusk">{children}</div>
      </div>
    </div>
  );
};

export const NoResults: FC<{
  className?: string;
  type?:
    | 'general'
    | 'work'
    | 'work-recommender'
    | 'work-recommender-landing-page'
    | 'careers'
    | 'learning'
    | 'resources'
    | 'profiles';
}> = ({ type = 'general', className }) => {
  const { t } = useTranslation();

  switch (type) {
    case 'profiles':
      return (
        <BaseNoResults
          title={t(
            `global.error.no_search_results.label_1_${type}`,
            'Looks like your search did not match any profiles'
          )}
        >
          <p className="my-6">{t('global.error.no_search_results.label_2')}</p>
          <ul className="leading-6 list-disc list-inside">
            <li>{t('global.error.no_search_results.label_3')}</li>
            <li>{t('global.error.no_search_results.label_4')}</li>
            <li>{t('global.error.no_search_results.label_5')}</li>
          </ul>
        </BaseNoResults>
      );

    case 'work-recommender-landing-page':
      // TODO: Add the correct copy
      return (
        <BaseNoResults
          className={className}
          svg={<SvgNoJobsWorkRec className="m-auto my-4" />}
          title={t(
            `global.error.no_search_results.label_1_${type}`,
            'Tailor your search for better matches!'
          )}
        >
          <ul className="mt-6 leading-6 list-disc">
            <li>
              <span className="font-bold">
                {t(
                  `global.error.no_search_results.label_2_1_${type}`,
                  'Broaden your Search'
                )}
                {': '}
              </span>
              {t(
                `global.error.no_search_results.label_2_2_${type}`,
                'Try expanding your job search criteria or location.'
              )}
            </li>
            <li>
              <span className="font-bold">
                {t(
                  `global.error.no_search_results.label_3_1_${type}`,
                  'Update your Profile'
                )}
                {': '}
              </span>
              {t(
                `global.error.no_search_results.label_3_2_${type}`,
                'Keep your profile updated to optimize your results.'
              )}
            </li>
            <li>
              <span className="font-bold">
                {t(
                  `global.error.no_search_results.label_4_1_${type}`,
                  'Check Back In'
                )}
                {': '}
              </span>
              {t(
                `global.error.no_search_results.label_4_2_${type}`,
                'Our jobs are sourced from multiple sources and updated daily.'
              )}
            </li>
          </ul>
        </BaseNoResults>
      );

    case 'work-recommender':
      return (
        <BaseNoResults
          sideways={true}
          title={t(
            `global.error.no_search_results.label_1_${type}`,
            'Try expanding your search for more relevant results.'
          )}
        >
          <p className="mt-6">
            {t(
              `global.error.no_search_results.label_2_${type}`,
              'View related jobs below or try the following:'
            )}
          </p>
          <ul className="leading-6 list-disc list-inside">
            <li>
              {t(
                `global.error.no_search_results.label_3_${type}`,
                'More general keywords to expand the search'
              )}
            </li>
            <li>{t('global.error.no_search_results.label_5')}</li>
            <li>
              {t(
                `global.error.no_search_results.label_4_${type}`,
                'Expand the distance from your location'
              )}
            </li>
            <li>
              {t(
                `global.error.no_search_results.label_6_${type}`,
                'Increase the date range from date posted'
              )}
            </li>
          </ul>
        </BaseNoResults>
      );

    default:
      return (
        <BaseNoResults
          title={t(`global.error.no_search_results.label_1_${type}`)}
        >
          <p className="my-6">{t('global.error.no_search_results.label_2')}</p>
          <ul className="leading-6 list-disc list-inside">
            <li>{t('global.error.no_search_results.label_3')}</li>
            <li>{t('global.error.no_search_results.label_4')}</li>
            <li>{t('global.error.no_search_results.label_5')}</li>
          </ul>
          <p className="my-6">
            {t('global.error.no_search_results.label_7')}{' '}
            <a
              href="mailto:help@futurefit.ai"
              className="text-purple hover:underline"
            >
              {t('global.error.no_search_results.label_8')}
            </a>
          </p>
        </BaseNoResults>
      );
  }
};
export interface DropdownSelectProps {
  id: string;
  children?: ReactNode;
  value: any;
  onChange: (e: any) => void;
  className?: string;
  disabled?: boolean;
  borderless?: boolean;
  placeholder?: string;
}

export const YearSelect = ({
  id,
  className,
  onChange,
  value,
  disabled,
  placeholder,
}: DropdownSelectProps) => {
  const currentYear = new Date().getFullYear();
  const start = currentYear;
  const stop = currentYear - 122;
  const step = -1;

  const yearOptions = Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => {
      const year = start + i * step;
      return (
        <option value={year} key={year}>
          {year}
        </option>
      );
    }
  );
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option value="">{placeholder}</option>
      {yearOptions.map((year) => year)}
    </DropdownSelect>
  );
};

export const MonthSelect = ({
  id,
  className,
  disabled,
  onChange,
  value,
  placeholder,
}: DropdownSelectProps) => {
  const { t } = useTranslation();
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option value="">{placeholder}</option>
      <option value="0">{t('months.january')}</option>
      <option value="1">{t('months.february')}</option>
      <option value="2">{t('months.march')}</option>
      <option value="3">{t('months.april')}</option>
      <option value="4">{t('months.may')}</option>
      <option value="5">{t('months.june')}</option>
      <option value="6">{t('months.july')}</option>
      <option value="7">{t('months.august')}</option>
      <option value="8">{t('months.september')}</option>
      <option value="9">{t('months.october')}</option>
      <option value="10">{t('months.november')}</option>
      <option value="11">{t('months.december')}</option>
    </DropdownSelect>
  );
};

export const EmploymentTypeSelect = ({
  id,
  className,
  disabled,
  onChange,
  value,
  placeholder,
}: DropdownSelectProps) => {
  const { t } = useTranslation();
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option defaultValue="">{placeholder}</option>
      <option value="full_time">
        {t('experience_types.full_time', 'Full-time')}
      </option>
      <option value="part_time">
        {t('experience_types.part_time', 'Part-time')}
      </option>
      <option value="self_employed">
        {t('experience_types.self_employed', 'Self-employed')}
      </option>
      <option value="contract">
        {t('experience_types.contract', 'Contract')}
      </option>
      <option value="internship_coop">
        {t('experience_types.internship_coop', 'Internship / Co-op')}
      </option>
      <option value="apprenticeship">
        {t('experience_types.apprenticeship', 'Apprenticeship')}
      </option>
      <option value="summer_job">
        {t('experience_types.summer_job', 'Summer job')}
      </option>
      <option value="volunteer">
        {t('experience_types.volunteer', 'Volunteer')}
      </option>
      <option value="freelance">
        {t('experience_types.freelance', 'Other')}
      </option>
    </DropdownSelect>
  );
};

export const getExperienceType = (key: string) => {
  switch (key) {
    case 'full_time':
      return i18n.t('experience_types.full_time', 'Full-time');
    case 'part_time':
      return i18n.t('experience_types.part_time', 'Part-time');
    case 'self_employed':
      return i18n.t('experience_types.self_employed', 'Self-employed');
    case 'contract':
      return i18n.t('experience_types.contract', 'Contract');
    case 'internship_coop':
      return i18n.t('experience_types.internship_coop', 'Internship / Co-op');
    case 'apprenticeship':
      return i18n.t('experience_types.apprenticeship', 'Apprenticeship');
    case 'summer_job':
      return i18n.t('experience_types.summer_job', 'Summer job');
    case 'volunteer':
      return i18n.t('experience_types.volunteer', 'Volunteer');
    case 'freelance':
      return i18n.t('experience_types.freelance', 'Other');
    default:
      return i18n.t('experience_types.full_time', 'Full-time');
  }
};

export const DegreeTypeSelect = ({
  id,
  className,
  disabled,
  onChange,
  value,
  placeholder,
}: DropdownSelectProps) => {
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option defaultValue="" hidden>
        {placeholder}
      </option>
      {Object.values(EducationType).map((educationType) => (
        <option key={educationType} value={educationType}>
          {getEducationTypeLabel(educationType)}
        </option>
      ))}
    </DropdownSelect>
  );
};

export const EducationStatusSelect = ({
  id,
  className,
  disabled,
  onChange,
  value,
  placeholder,
}: DropdownSelectProps) => {
  const { t } = useTranslation();
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option defaultValue="">{placeholder}</option>
      <option value="completed">
        {t('education_status.completed', 'Completed')}
      </option>
      <option value="not_completed">
        {t('education_status.not_completed', 'Not completed')}
      </option>
    </DropdownSelect>
  );
};
export interface DragAndDropResumeProps {
  title: string;
  subtitle: string;
  onChange: (file: File) => void;
  className?: string;
  id?: string;
  name?: string;
}

export const DragAndDropResume = (props: DragAndDropResumeProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { title, subtitle, onChange, className = '' } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target?.files?.length) {
      return;
    }
    onChange(e.target.files[0] as File);
  };

  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event?.dataTransfer?.files?.length) {
      return;
    }
    onChange(event.dataTransfer.files[0] as File);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER || event.key === SPACE) {
      hiddenFileInput?.current?.click();
    }
  };

  const preventEvent = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <label
      className={`h-40 mb-6 rounded-sm cursor-pointer sm:w-1/2 ${className}`}
      htmlFor={props.id ?? 'resume_file_input'}
    >
      <div
        tabIndex={0}
        className="flex flex-col items-center p-6 bg-light-grey"
        onDrop={handleDrop}
        onDragEnter={preventEvent}
        onDragOver={preventEvent}
        onKeyDown={handleKeyDown}
      >
        <svg
          width="32"
          height="32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.8 20.266v5.689a2.844 2.844 0 0 1-2.845 2.844H6.046A2.844 2.844 0 0 1 3.2 25.954v-5.688M23.11 10.31 16 3.2l-7.112 7.11M16 3.2v17.066"
            stroke="#00264A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <Text size="xs" className="!font-medium text-center p-5">
          {title}
        </Text>
        <Text className="text-center text-concrete" size="xs">
          {subtitle}
        </Text>
      </div>
      <input
        className="hidden"
        id={props.id ?? 'resume_file_input'}
        type="file"
        name={props.name ?? 'resume_file'}
        ref={hiddenFileInput}
        onClick={(e) => {
          e.currentTarget.value = '';
        }}
        onChange={handleChange}
      />
    </label>
  );
};

export interface DragAndDropProviderLogoProps {
  title: string;
  subtitle: string;
  onChange: (file: File) => void;
  resetRequest: boolean;
  className?: string;
}

export const DragAndDropProviderLogo = (
  props: DragAndDropProviderLogoProps
) => {
  const hiddenLogoFileInput = useRef<HTMLInputElement>(null);
  const { title, subtitle, onChange, resetRequest, className = '' } = props;
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target?.files?.length) {
      return;
    }
    onChange(e.target.files[0] as File);
    setFileName((e.target.files[0] as File).name);
    setSelectedFile(e.target.files[0]);
  };

  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event?.dataTransfer?.files?.length) {
      return;
    }
    onChange(event.dataTransfer.files[0] as File);
  };

  const preventEvent = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER || event.key === SPACE) {
      hiddenLogoFileInput?.current?.click();
    }
  };

  return (
    <label
      className={`h-40 mb-6 rounded-sm cursor-pointer sm:w-1/2 ${className}`}
      htmlFor="logo_file_input"
    >
      {fileName && resetRequest ? (
        <div
          tabIndex={0}
          className="flex flex-col items-center p-6 bg-light-grey"
          onDrop={handleDrop}
          onDragEnter={preventEvent}
          onDragOver={preventEvent}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        >
          <img
            className="mb-2"
            width="128"
            alt="logo"
            src={URL.createObjectURL(selectedFile as Blob)}
          />
          <Text size="xs" className="!font-medium text-center mt-2">
            {`${fileName}`}
          </Text>
        </div>
      ) : (
        <div
          tabIndex={0}
          className="flex flex-col items-center p-6 bg-light-grey"
          onDrop={handleDrop}
          onDragEnter={preventEvent}
          onDragOver={preventEvent}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        >
          <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.8 20.266v5.689a2.844 2.844 0 0 1-2.845 2.844H6.046A2.844 2.844 0 0 1 3.2 25.954v-5.688M23.11 10.31 16 3.2l-7.112 7.11M16 3.2v17.066"
              stroke="#00264A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text size="xs" className="!font-medium text-center p-5">
            {title}
          </Text>
          <Text className="text-center text-concrete" size="xs">
            {subtitle}
          </Text>
        </div>
      )}

      <input
        className="hidden"
        id="logo_file_input"
        type="file"
        name="logo_file"
        ref={hiddenLogoFileInput}
        onClick={(e) => {
          e.currentTarget.value = '';
        }}
        onChange={handleChange}
      />
    </label>
  );
};

export interface DragAndDropResumeMessageProps {
  title?: string;
  image?: string;
  alt?: string;
  svgIcon?: React.SVGProps<SVGSVGElement>;
  backgroundColorCode: string;
  colorCode: string;
  errorMessage: string;
  onClick?: () => void;
  className?: string;
  errorMessageClassName?: string;
  showCloseIcon?: boolean;
}
export const DragAndDropResumeMessageBox = (
  props: DragAndDropResumeMessageProps
) => {
  const {
    title,
    image,
    alt,
    svgIcon,
    backgroundColorCode,
    errorMessage,
    colorCode,
    onClick,
    className = '',
    errorMessageClassName = '',
    showCloseIcon = false,
  } = props;
  const { t } = useTranslation();
  const imageUploadCase = image && !svgIcon;

  return (
    <>
      <div
        data-testid="Drag_And_Drop_Resume_Message_Box"
        onClick={onClick}
        className={`sm:w-1/2 h-44 flex justify-center items-center p-6 rounded-sm relative ${
          onClick ? 'cursor-pointer' : ''
        } ${className}`}
        style={{ backgroundColor: backgroundColorCode }}
      >
        {showCloseIcon && (
          <IconCancel size={20} className="absolute top-3 right-3" />
        )}
        {imageUploadCase ? (
          <div className="relative w-full group">
            <div
              className="w-full flex justify-center items-center group-hover:brightness-[0.25]"
              style={{ backgroundColor: backgroundColorCode }}
            >
              <img className="h-44 min-w-[100px]" src={image} alt={alt}></img>
            </div>
            <Button
              className="!hidden absolute !text-white group-hover:!flex inset-0"
              variant="tertiary"
              size="lg"
            >
              <IconCancel className="pr-2" size={16} />
              {t('clear.uploaded.image', 'Clear')}
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center bg-red-400">
            {svgIcon}
            <Text
              size="sm"
              className="!font-medium text-center pt-5"
              style={{ color: colorCode }}
            >
              {title}
            </Text>
          </div>
        )}
      </div>
      <div
        className={`sm:w-1/2 ${errorMessageClassName}`}
        style={{ color: colorCode }}
      >
        {errorMessage}
      </div>
    </>
  );
};

export const StyledSearchInputButton = styled(Button)`
  display: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  @media (min-width: 1024px) {
    display: flex !important;
  }
`;

export const SearchInputButton: FC<{
  className?: string;
  onClick?: () => void;
}> = ({ className = '', onClick, ...rest }) => {
  const { t } = useTranslation();
  return (
    <StyledSearchInputButton
      onClick={() => {
        onClick && onClick();
      }}
      variant="primary"
      className={`h-full hidden ${className}`}
      aria-label={t('search.submit_search', 'Submit search')}
      {...rest}
    >
      <SvgSearchIcon size={20} />
    </StyledSearchInputButton>
  );
};

export const DismissibleFilter: FC<{
  className?: string;
  onClick?: () => void;
}> = ({ className = '', onClick, children }) => {
  return (
    <button
      className={`py-2.5 px-4 bg-dawn rounded-md flex gap-2 items-center text-left ${className}`}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <p className="text-xs font-primary">{children}</p>
      <IconCancel size={20} />
    </button>
  );
};

export const SelectedFiltersList: FC = ({ children }) => {
  return <div className="flex flex-row flex-wrap gap-2">{children}</div>;
};

export const getSupportNeedIcon = (need: string) => {
  switch (need) {
    case 'resume':
      return <IconResume size={16} />;

    case 'job_search':
      return <IconJobSearch size={16} />;

    case 'personal_brand':
      return <IconPersonalBrand size={16} />;

    case 'financial_planning':
      return <IconFinancialPlanning size={16} />;

    case 'financial_support':
      return <IconFinancialSupport size={16} />;

    case 'mentorship':
      return <IconMentorship size={16} />;

    case 'networking':
      return <IconNetworking size={16} />;

    case 'health_wellness':
      return <IconHealthAndWellness size={16} />;

    case 'pay_negotiation':
      return <IconPayNegotiation size={16} />;

    case 'freelance':
      return <IconFreelance size={16} />;

    case 'interviews':
      return <IconInterviews size={16} />;

    case 'entrepreneurship':
      return <IconEntreprneurship size={16} />;
  }
};

export const StyledDropdown = styled.ul<{
  hideDropdown?: boolean;
  virtual?: boolean;
}>`
  ${tw`absolute z-30 flex flex-col overflow-auto bg-white rounded-md shadow-md cursor-default top-11 max-h-52 w-max`};

  ${({ virtual }) => (virtual ? tw`py-0` : tw`py-2`)};

  ${({ hideDropdown }) => (hideDropdown ? tw`invisible` : tw`visible`)};
`;

export const DropdownFilter: FC<{
  label?: string;
  selectedLabel?: string;
  className?: string;
  virtual?: boolean;
}> = ({
  children,
  className = '',
  label,
  selectedLabel,
  virtual = false,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLUListElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const intersection = useIntersectionObserver(popoverRef, {
    root: document.getElementById('#root'),
    threshold: [1],
  });

  useLayoutEffect(() => {
    if (!intersection?.rootBounds) return;

    const { rootBounds, boundingClientRect } = intersection;

    const isIntersectingLeft = boundingClientRect.x < 0;
    const isIntersectingRight =
      boundingClientRect.x + boundingClientRect.width > rootBounds.width;

    if (isIntersectingLeft && isIntersectingRight) return;

    if (isIntersectingLeft) {
      popoverRef.current?.classList.remove('right-5');
      popoverRef.current?.classList.add('left-0');
    }

    if (isIntersectingRight) {
      popoverRef.current?.classList.remove('left-0');
      popoverRef.current?.classList.add('right-5');
    }
  }, [intersection]);

  useEffect(() => {
    const handleEvent: EventListener = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener('click', handleEvent);
    window.addEventListener('scroll', handleEvent);

    return () => {
      window.removeEventListener('click', handleEvent);
      window.removeEventListener('scroll', handleEvent);
    };
  }, [showDropdown]);

  return (
    <div
      className={`${className} relative rounded-md bg-dawn-30 px-4 py-2.5 cursor-pointer min-w-52`}
      ref={dropdownRef}
      {...rest}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
    >
      <button
        className="flex items-center justify-between h-full space-x-2"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <div>
          <p className="text-xs font-primary">{label}</p>
          <p className="text-concrete text-xxs">{selectedLabel}</p>
        </div>
        {showDropdown ? (
          <IconCollapse className="pointer-events-none" size={16} />
        ) : (
          <IconExpand className="pointer-events-none" size={16} />
        )}
      </button>

      <StyledDropdown
        ref={popoverRef}
        virtual={virtual}
        hideDropdown={!showDropdown}
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation();
          setShowDropdown(true);
        }}
      >
        {children}
      </StyledDropdown>
    </div>
  );
};

export const DropdownFilterListItem: FC<{
  onClick?: MouseEventHandler;
  value?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler;
  disabled?: boolean;
  style?: any;
  id: string;
}> = ({ onClick, onChange, value, checked, children, disabled, style, id }) => {
  return (
    <li
      className="w-full max-w-xs px-4 py-2 break-all cursor-pointer hover:bg-dawn-30"
      onClick={onClick}
      style={style}
    >
      <CheckboxInput
        id={id}
        readOnly={checked !== undefined && !onChange}
        className="w-full h-full truncate cursor-pointer"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled || false}
        labelClassName="truncate"
      >
        {children}
      </CheckboxInput>
    </li>
  );
};

export const ServiceStagesSelect = ({
  id,
  className,
  disabled,
  onChange,
  value,
  placeholder,
}: DropdownSelectProps) => {
  const { t } = useTranslation();
  return (
    <DropdownSelect
      id={id}
      className={`mt-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      <option defaultValue="" hidden>
        {placeholder}
      </option>
      <option value="assess">{t('services_stages.assess', 'Assess')}</option>
      <option value="explore">{t('services_stages.explore', 'Explore')}</option>
      <option value="learn">{t('services_stages.learn', 'Learn')}</option>
      <option value="work">{t('services_stages.work', 'Work')}</option>
      <option value="off_board">
        {t('services_stages.off_board', 'Offboard')}
      </option>
    </DropdownSelect>
  );
};

export const getServiceStage = (key: string) => {
  switch (key) {
    case 'assess':
      return i18n.t('experience_types.assess', 'Assess');
    case 'explore':
      return i18n.t('experience_types.explore', 'Explore');
    case 'learn':
      return i18n.t('experience_types.learn', 'Learn');
    case 'work':
      return i18n.t('experience_types.work', 'Work');
    case 'off_board':
      return i18n.t('experience_types.off_board', 'Offboard');
    default:
      return;
  }
};

export const DropdownFilterRole: FC<{
  label?: string;
  selectedLabel?: string;
  className?: string;
  onClick?: MouseEventHandler;
}> = ({
  children,
  className = '',
  label,
  selectedLabel,
  onClick,
  ...props
}) => {
  const dropdownRef = useRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (onClick) {
      setShowDropdown(false);
    }
  }, [onClick]);

  useEffect(() => {
    const handleEvent: EventListener = (e) => {
      if (dropdownRef?.current && !dropdownRef?.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    window.addEventListener('click', handleEvent);
    window.addEventListener('scroll', handleEvent);
    return () => {
      window.removeEventListener('click', handleEvent);
      window.removeEventListener('scroll', handleEvent);
    };
  }, [showDropdown]);

  return (
    <div
      className={`${className} relative rounded-md px-4 py-2.5 cursor-pointer`}
      ref={dropdownRef}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
      {...props}
    >
      <button
        className="flex items-center justify-between h-full space-x-2 "
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <p className="text-xs font-primary">
          {label} <span className="text-concrete">{selectedLabel}</span>
        </p>
        {showDropdown ? (
          <IconCollapse className="pointer-events-none" size={16} />
        ) : (
          <IconExpand className="pointer-events-none" size={16} />
        )}
      </button>

      {showDropdown && (
        <StyledDropdown
          data-testid="roles-dropdown"
          className="border border-dawn"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            setShowDropdown(true);
          }}
        >
          {children}
        </StyledDropdown>
      )}
    </div>
  );
};

export const DropdownFilterRoleListItem: FC<{
  onClick?: MouseEventHandler;
  value?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler;
}> = ({ onClick, onChange, value, checked, children }) => {
  return (
    <li
      className={`w-full px-4 py-2 cursor-pointer hover:bg-dawn-30 ${
        checked && 'bg-dawn-30'
      }`}
      onClick={onClick}
    >
      <RadioInput
        readOnly={checked !== undefined && !onChange}
        className="relative w-full h-full leading-none cursor-pointer radio"
        value={value}
        checked={checked}
        onChange={onChange}
      >
        {children}
      </RadioInput>
    </li>
  );
};

export const Skeleton = ({
  lines = 1,
  className = '',
}: {
  lines?: number;
  className?: string;
}) => {
  return (
    <span className={`${className} flex flex-col gap-y-2`}>
      {Array.from(Array(lines)).map((_, idx) => (
        <span
          key={idx}
          className="w-full text-transparent bg-dawn-30 animate-pulse"
        >
          {`:)`}
        </span>
      ))}
    </span>
  );
};

export const ButtonMailto = ({
  mailto,
  className = '',
  children,
}: {
  mailto: string;
  className?: string;
  children?: string;
}) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
      className={`px-4 py-2 text-xs font-semibold text-black rounded bg-gold hover:bg-gold-80 font-secondary whitespace-nowrap ${className}`}
    >
      {children}
    </Link>
  );
};

export const StyledNumberInput = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SalaryInput: FC<{
  onCurrencyChange?: (currency: Currency) => void;
  onSalaryChange?: (salary?: number) => void;
  salaryValue?: number;
  currencyValue?: SupportedCurrency;
}> = ({ onCurrencyChange, onSalaryChange, currencyValue, salaryValue }) => {
  const { isMobile } = useIsMobile();
  const [focus, setFocus] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencyValue ?? SupportedCurrency.USD
  );

  /**
   * Helper function for formatting currency data to be displayed and consumed
   * @param values - Currency
   */
  const retrieveCurrencyOption = ({
    currencyCode,
    currencySymbol,
    currencyName,
  }: Currency) => ({
    value: currencyCode,
    label: isMobile ? currencyCode : `${currencyName} (${currencySymbol})`,
  });

  return (
    <div
      onBlur={() => {
        setFocus(false);
      }}
      onClick={() => {
        setFocus(true);
      }}
      className={`border flex items-center rounded-md w-full ${
        focus ? 'border-purple' : 'border-dusk'
      }`}
    >
      <StyledNumberInput
        className="pl-4 py-3.5 rounded-l-md focus:ring-0 focus:outline-none text-xs text-concrete w-full"
        type="number"
        aria-label="Salary"
        placeholder="75000"
        value={salaryValue ?? ''}
        min={0}
        onChange={(e) => {
          e.target.reportValidity();

          onSalaryChange &&
            onSalaryChange(
              isNaN(toNumber(e.target.value))
                ? undefined
                : toNumber(e.target.value)
            );
        }}
        tabIndex={0}
      />

      <div className="my-3.5 w-px bg-dawn text-transparent">{':)'}</div>

      <UniversalDropdownSelect
        onBlur={() => {
          setFocus(false);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onChange={(option: Option) => {
          const currencyCode = option.value as SupportedCurrency;

          onCurrencyChange && onCurrencyChange(CurrencyCode.get(currencyCode));
          setSelectedCurrency(currencyCode);
        }}
        tabIndex={0}
        defaultValue={retrieveCurrencyOption(
          CurrencyCode.get(selectedCurrency)
        )}
        borderless
        textAlign="right"
        className="text-xs bg-transparent focus:outline-none focus:ring-0 rounded-r-md text-concrete"
        options={CurrencyCode.getAll().map(retrieveCurrencyOption)}
      />
    </div>
  );
};

/**
 * This function can be used with drop down lists like <StyledDropdownOptions> component to get and return the first item/option in the list. From there you can do as you please, i.e. simulate a click on that first option, change colour of that first option etc.
 * @param {React.RefObject<T>} ref - A reference to the HTMLUListElement that contains the list of options.
 */

export const selectFirstListOption = <T extends HTMLUListElement>(
  ref: RefObject<T>
) => {
  return ref.current?.querySelector('li:nth-child(1)') as HTMLElement;
};
